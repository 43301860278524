import { createApp } from 'vue';
import App from './App.vue';
import '@assets/less/reset.less';

function render(props: any = {}) {
  const { container } = props;
  instance = createApp(App)
    .mount(container ? container.querySelector('#micro-app') : '#micro-app');
}

// 定义变量
let instance = null as any;

// 独立运行子应用
if (!(window as any).__POWERED_BY_QIANKUN__) {
  render();
}



export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

export async function mount(props: any) {
  console.log('[vue] props from main framework');
  render(props);
}

export async function unmount() {
  instance.$el.innerHTML = '';
  instance = null;
}