<template>
  <div class="wrapper">
    <img class="logo" src="./imgs/logo.png" />
    <img @click="jumpAdmin" class="login" src="./imgs/login.png" />
    <div @click="showContent" class="top">
      <span type="home" :class="{'active': currentType === 'home'}" />
      <span type="desc" :class="{'active': currentType === 'desc'}" />
      <span type="connect" :class="{'active': currentType === 'connect'}" />
      <span type="cooperate" :class="{'active': currentType === 'cooperate'}" />
    </div>
    <img class="bottom" src="./imgs/bottom.png" />
    <a href="https://beian.miit.gov.cn/" class="beian">京ICP备2023004820号</a>
    <div class="content">
      <img :src="currentSrc" />
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import home from './imgs/home-page.png'
import desc from './imgs/desc-page.png'
import connect from './imgs/connect-page.png'
import cooperate from './imgs/cooperate-page.png'

const contents = {
  cooperate,
  home,
  desc,
  connect
}
const currentSrc = ref(home)
const currentType = ref('home')
const showContent = e => {
  const target = e.target
  if (target.tagName === 'SPAN') {
    const type = target.getAttribute('type')
    currentType.value = type
    currentSrc.value = contents[type]
  }
}
const jumpAdmin = () => {
  location.href = '/admin.html'
}
</script>
<style lang="less" scoped>

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  min-width: 1000px;
  height: 100vh;
  position: relative;
  // background: url("./imgs/background.png") no-repeat;
  background-size: cover;
  box-sizing: border-box;
  overflow-y: hidden;
}
.top {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  box-sizing: border-box;
  padding-right: 200px;
  & > span {
    width: 100px;
    height: 40px;
    background-size: 100% 100%;
    margin-right: 20px;
    cursor: pointer;
    &:first-child {
      background-image: url("./imgs/home.png");
      &:hover, &.active {
        background-image: url("./imgs/home-active.png");
      }
    }
    &:nth-child(2) {
      background-image: url("./imgs/desc.png");
      &:hover, &.active {
        background-image: url("./imgs/desc-active.png");
      }
    }
    &:nth-child(3) {
      background-image: url("./imgs/connect.png");
      &:hover, &.active {
        background-image: url("./imgs/connect-active.png");
      }
    }
    &:nth-child(4) {
      background-image: url("./imgs/cooperate.png");
      &:hover, &.active {
        background-image: url("./imgs/cooperate-active.png");
      }
    }
  }
}
.logo {
  position: absolute;
  top: 10px;
  left: 50px;
  width: 185px;
  z-index: 100;
}
.login {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 100;
  width: 150px;
  cursor: pointer;
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
}
.beian {
  color: #fff;
  position: fixed;
  z-index: 10;
  left: 50%;
  margin-left: -70px;
  bottom: 3px;
}
.content {
  width: 100%;
  position: absolute;
  top: 80px;
  height:  calc(100% - 113px);
  left: 0;
  & > img {
    width: 100%;
    height: 100%;
  }
}
</style>
